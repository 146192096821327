<template>
  <div>
    <b-input-group prepend="+62">
      <b-form-input
        v-model="customerPhone"
        type="number"
        :class="requireCustomerPhone ? 'bg-[#FFEDED] border-[1px] border-[#E31A1A]' : 'bg-[ffffff]'"
        :formatter="validatePhone"
        @update="checkWhatsapp"
        @paste.prevent="handlePaste"
        @keypress="isNumber($event)"
      />
      <b-input-group-append is-text>
        <span
          v-if="whatsappStatus !== null"
          :id="customerPhone"
          type="button"
          :class="whatsappStatus.textColor"
          class="k-Whatsapp text-[22px] cursor"
        />
        <span
          v-else
          class="k-Whatsapp text-[22px]"
        />
        <b-tooltip
          v-if="whatsappStatus !== null"
          :target="customerPhone"
          placement="top"
          triggers="hover"
          custom-class="mt-2"
        >
          {{ whatsappStatus.message }}
        </b-tooltip>
      </b-input-group-append>
    </b-input-group>
    <div v-if="messageError !== ''">
      <small class="mr-1 text-primary">
        *{{ messageError }}
      </small>
    </div>
  </div>
</template>

<script>
import { isNumber } from '@/libs/helpers'

export default {
  props: {
    valuePhone: {
      type: String,
      default: '',
    },
    checkWa: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isNumber,
      customerPhone: '',
      requireCustomerPhone: null,
      messageError: '',
      isWhatsapp: null,
      contentTooltip: 'ini content tooltip',
    }
  },
  computed: {
    whatsappStatus() {
      if (this.isWhatsapp === 'valid') {
        return {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/whatsapp.svg',
          message: 'Nomor ini terhubung ke Whatsapp',
          content: '',
          textColor: 'text-success',
        }
      } if (this.isWhatsapp === 'invalid') {
        return {
          icon: 'https://storage.googleapis.com/komerce/assets/icons/danger-red.svg',
          message: 'Nomor ini tidak terhubung ke Whatsapp',
          content: '',
          textColor: 'text-danger',
        }
      } if (this.isWhatsapp !== null) {
        return {
          message: 'Maaf, terdapat masalah saat melakukan cek nomor',
          content: '',
          textColor: 'text-[#FBA63C]',
        }
      }
      return null
    },
  },
  watch: {
    valuePhone: {
      handler(newVal) {
        if (newVal.length > 9) {
          let phone = newVal
          const startsWithEight = newVal.startsWith('8')
          if (!startsWithEight) {
            phone = newVal.slice(1)
          }
          this.customerPhone = this.validatePhone(phone)
          this.checkWhatsapp()
        }
      },
      immediate: true,
    },
    requireCustomerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
    customerPhone: {
      handler() {
        this.updateValue()
      },
      deep: true,
    },
  },
  methods: {
    updateValue() {
      this.$emit('update:dataForParent', {
        customerPhone: this.customerPhone,
        requireCustomerPhone: this.requireCustomerPhone,
      })
    },
    validatePhone(value) {
      this.messageError = ''
      const phone = value.replace(/[^\d]/g, '')
      const startsWithEight = phone.startsWith('8')
      const isRequired = phone.length === 0
      this.requireCustomerPhone = phone.length < 9 || phone.length >= 15 || !startsWithEight || isRequired

      if (isRequired) {
        this.messageError = 'Wajib diisi ya'
        this.isWhatsapp = null
        return value
      }

      if (value.startsWith('62')) {
        this.isWhatsapp = null
        return value.slice(2)
      }

      if (!startsWithEight) {
        this.messageError = 'Wajib di mulai angka 8'
        this.isWhatsapp = null
        return value.slice(1)
      }

      if (phone.length < 9) {
        this.messageError = 'Minimal 9 angka'
        this.isWhatsapp = null
      }

      if (phone.length > 15) {
        this.messageError = 'Maksimal 15 karakter'
        this.isWhatsapp = null
        return value.substring(0, 15)
      }
      return value
    },
    checkWhatsapp: _.debounce(async function () {
      if (String(this.customerPhone).length >= 9 && this.checkWa) {
        await this.$http_komship.post(`/v1/check-wa?phone_no=${this.customerPhone}`)
          .then(res => {
            const { data } = res.data
            this.isWhatsapp = data
          })
          .catch(error => {
            const { data } = error.response.data
            this.isWhatsapp = data
          })
        this.updateValue()
      } else {
        this.isWhatsapp = null
      }
    }, 500),
    handlePaste(event) {
      const pasteContent = (event.clipboardData || window.clipboardData).getData('text')
      const sanitizedContent = pasteContent.replace(/\D/g, '')
      this.customerPhone = sanitizedContent
      const index = this.customerPhone.indexOf('8')
      if (index !== -1) {
        const secondPart = this.customerPhone.substring(index)
        this.customerPhone = `${secondPart}`
      }
      this.checkWhatsapp()
      event.preventDefault()
    },
  },
}
</script>
<style lang="scss">
.info-wa {
  background-color: #BEE6FC;
  font-size: 12px;
  color: #08A0F7;
}
</style>
